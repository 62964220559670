<template>
    <div style="background:#000;">
    </div>
    </template>
    
    <script>
    export default {
        name: 'NotFound',
        components: {
    
      },
      data () {
            return {
          
        }
      },
        methods:{
            
        }
    }
    </script>
    <style scoped>
    </style>
    