<template>
	<div>
		<div style="height:100%;width:100%;position:absolute;z-index:0;top:0;left:0;">
			<div class="title">{{sense[sense_id].name}}
				<br/><div style="font-size:.8rem;color:#D1C4E9;margin-top:.5em;">{{sense[sense_id].id}}</div>
			</div>
		  	<Pano :source=vrImg></Pano>
		</div>
		<div id="threeContained" style="pointer-events:none;position:absolute;z-index:1;"></div>
		<div class="introduce-menu">
			<h3 style="margin:.8em;">{{test.name}}</h3>
			<div class="container">
				<div class="skip-btn" @click="lastPart">
					<font-awesome-icon icon="fa-chevron-left" style="font-size:1em;"/>
					<h5 style="margin:.2em;">上一站</h5>
				</div>
				<div class="item" @click="audioPlay">
					<font-awesome-icon icon="fa-headphones-simple" style="font-size:1.5em;"/>
					<h4 style="margin:.2em;">{{text}}</h4>
				</div>
				<div class="item" @click="conversation">
					<font-awesome-icon icon="fa-user-group" style="font-size:1.5em;"/>
					<h4 style="margin:.2em;">對話</h4>
				</div>
				<div class="skip-btn" @click="nextPart">
					<font-awesome-icon icon="fa-chevron-right" style="font-size:1em;"/>
					<h5 style="margin:.2em;">下一站</h5>
				</div>
			</div>
		</div>
		<div class="back-btn" @click="back2Main">回情境選單</div>
	</div>
</template>
<script>
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { Pano } from 'vuejs-vr'
import * as THREE from 'three';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from "three/examples/jsm/libs/stats.module";
export default {
	name: 'VRView',
	components: {
		Pano
	},
  	props: {
    	scene_index: {
        	type: Object
    	},
  	},
	data () {
		return {
			nextQAInterval: null,
			speechServiceInterval: null,
			conversationTimer: null,
			firstTime: true,
			response: '55123',

			answer: null,
			valueZ: -0.2,
			valueScale: [{ x:0.004,y:0.005,z:0.05 },{ x:0.0025,y:0.0015,z:0.05 }],
			currentScale: { x:0.0025,y:0.0015,z:0.05 },
			sense_id: 0,
			value: 0,
			point_name: "",
			scene: "",
			light: "",
			camera: "",
			controls: "",
			renderer: "",
			load:'',
			clock:'',
			mixer:'',
			audio: null,
			number: 0,
			number2: 5,
			sense: [{
				name: '工豐企業',
				audioName: '工豐',
				id: 'GONG FONG',
				model: 'boss1',
				view: [
					{ name: '工豐大門', img: 'part1' },
					{ name: '戰情室', img: 'part2' },
					{ name: 'CNC金屬加工', img: 'part3' },
					{ name: '雷雕工序', img: 'part4' },
					{ name: '塑料射出', img: 'part5' },
					{ name: '出貨', img: 'part6' }]
			},
      {
				name: '世德工業',
				audioName: '世德',
				id: 'SUMEEKO',
				model: 'boss2',
				view: [
					{ name: '世德屏東廠', img: 'part1', sub_view: { name: '世德大寮廠', img: 'part7' }},
					{ name: '線材存放區', img: 'part2' },
					{ name: '螺絲成型', img: 'part3' },
					{ name: '螺絲搓牙', img: 'part4' },
					{ name: '熱處理', img: 'part5', sub_view: { name: '中控室', img: 'part6' }},

					{ name: '自動檢查機', img: 'part8' },
					{ name: '自動化塗裝', img: 'part9' },
					{ name: '特殊加工', img: 'part10' },
					{ name: '品保', img: 'part11' },
					{ name: '研發', img: 'part12' }]
			},
      		{
				name: '億曜企業',
				audioName: '億曜',
				id: 'MOLOMASTER',
				model: 'boss3',
				view: [
					{ name: '億曜大門', img: 'part1' },
					{ name: '電箱組裝', img: 'part2' },
					{ name: '電箱測試', img: 'part3' },
					{ name: '機台組裝', img: 'part4' },
					{ name: '整機大型機', img: 'part5' },
					{ name: '研發測試', img: 'part6' }]
			}],
			speechRecognizer: null,
			closeConversation: true
		}
	},
  	methods: {
		conversation(){
			this.initService();
			this.closeConversation = false;

			if(this.firstTime){
				this.firstTime = false;
				this.audio.src = `https://www.iii-vr.net/message/params?name=${this.sense[this.sense_id].audioName}&AB=B&num=0`;
				this.audio.play();
			}
			setTimeout(()=>{
				if(this.audio.ended){
					navigator.mediaDevices.getUserMedia({ audio: true }).then(()=>{
						console.log('init...');
						this.startStream();
						this.speechServiceInterval = setInterval(this.startStream, 3000);
					}).catch((error)=>{
						alert(error);
					})
				}
			}, 4000);
    	},
		startStream(){
			let name = this.sense[this.sense_id].audioName;
      		if(this.audio.ended&&!this.closeConversation){
				const speechConfig = sdk.SpeechConfig.fromSubscription('52b4392d494146b29a4040b8a34ab3ec', 'eastasia');
				speechConfig.speechRecognitionLanguage = "zh-tw";
				let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
				this.speechRecognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
        		this.speechRecognizer.recognizeOnceAsync(result => {

          			switch (result.reason) {
            			case sdk.ResultReason.RecognizedSpeech:
							this.resultEnd = true;
							this.response = result.text;
							this.audio.src = `https://www.iii-vr.net/QA/params?name=${name}&AB=B&text=${result.text}`;
							this.audio.play();
							this.resultEnd = true;
							this.nextQAInterval = setInterval(()=>{
								// 定時檢查，對話完成沒
								if(this.audio.ended&&!this.firstTime&&this.resultEnd){
									this.resultEnd = false;
									this.audio.src = `https://www.iii-vr.net/message/params?name=${name}&AB=B&num=4`;
									this.audio.play();
								}
							},1000);
              			break;
            			case sdk.ResultReason.NoMatch:
							this.number ++;
							if(this.number>=10){
							this.audio.src = `https://www.iii-vr.net/message/params?name=${name}&AB=B&num=1`;
							this.audio.play();
							this.number = 0;
							}
              				// console.log("NOMATCH: Speech could not be recognized.");
              			break;
            			case sdk.ResultReason.Canceled:
              				const cancellation = sdk.CancellationDetails.fromResult(result);
              				console.log(`CANCELED: Reason=${cancellation.reason}`);

              				if (cancellation.reason == sdk.CancellationReason.Error) {
								console.log(`CANCELED: ErrorCode=${cancellation.ErrorCode}`);
								console.log(`CANCELED: ErrorDetails=${cancellation.errorDetails}`);
								console.log("CANCELED: Did you set the speech resource key and region values?");
							}
              			break;
          			}

					this.speechRecognizer.close();
				});
      		}
		},
		initService(){
			this.firstTime = true;
      this.number = 0;
			this.closeConversation = true;
            this.audio.pause();
		    this.audio.currentTime = 0;
			if(this.nextQAInterval!=null){
				clearInterval(this.nextQAInterval);
			}
			if(this.speechServiceInterval!=null){
				clearInterval(this.speechServiceInterval);
			}
			if(this.speechRecognizer!=null&&!this.speechRecognizer.privDisposed&&!this.speechRecognizer.privDisposedRecognizer){
				this.closeConversation = true;
				this.speechRecognizer.close();
			}
		},
		audioPlay(){
			this.initService();
			if(this.sense_id!=null&&this.sense_id!=undefined&&this.value!=null){
				this.audio.src = `https://www.iii-vr.net/introduction/params?name=${this.sense[this.sense_id].audioName}&real=N&num=${this.value}&AB=A`;
				this.audio.play();
			}
		},
		back2Main(){
			this.initService();
			this.$router.push({ name: 'Main' });
		},
		lastPart(){
			this.initService();
			if(this.value>0){
				this.value = this.value-1;
			}
		},
        nextPart(){
			this.initService();
			if(this.value<this.sense[this.sense_id].view.length-1){
				this.value = this.value+1;
			}else{
                this.value = 0;
            }
		},
    	init() {
      		var that = this;
      		let container = document.getElementById("threeContained");
      		that.scene = new THREE.Scene();
      		// that.scene.background = new THREE.Color(0x000000, 0);
      		that.camera = new THREE.PerspectiveCamera(
				window.innerWidth / window.innerHeight,
      		);
            that.camera.position.x = 0;
            that.camera.position.y = 0;
            that.camera.position.z =  0;
            that.camera.lookAt(that.scene.position)
            // var axes = new THREE.AxesHelper(100)
            // that.scene.add(axes);
            // var planeGeometry = new THREE.PlaneGeometry(1000, 600);
            // var planeMaterial = new THREE.MeshStandardMaterial({ color:null });
            // var plane = new THREE.Mesh(planeGeometry, planeMaterial);
			      // plane.material.opacity = 1;

			      // plane.material.transparent = true;

			      // plane.rotation.x = -0.5 * Math.PI;

            // plane.position.x = 0;

            // plane.position.y = 0;

            // plane.position.z = 0;

            // plane.castShadow = false;
            // plane.receiveShadow = false;

			// that.scene.add(plane);
			// const pointLight = new THREE.PointLight(0xffffff, 1.5, 0);
			// pointLight.position.x = 8;
			// pointLight.position.y = -10;
			// pointLight.position.y = -200;
			// that.scene.add(pointLight);
			const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
			// hemiLight.position.set(8, 1000, 10);
			// hemiLight.rotation.set(-100,-100,-100);
			// that.scene.add(hemiLight);

			// const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.8);
			// directionalLight1.position.set(0, -10, -10);
			// that.scene.add(directionalLight1);

			// const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.8);
			// directionalLight2.position.set(8, -100, -10);
			// directionalLight1.rotation.x = -Math.PI;
			// that.scene.add(directionalLight2);

			const ambientLight = new THREE.AmbientLight(0xffffff, 1.8);
			ambientLight.position.set( 8, 0, -10);
			that.scene.add(ambientLight);

      		that.stats = new Stats();

      		container.appendChild(that.stats.dom);

      // const normal = new THREE.TextureLoader();//.load( 'models/shanghai/textures/shanghai.jpg' );

      		that.loader = new GLTFLoader();
			const dracoLoader = new DRACOLoader();
			dracoLoader.setDecoderPath('draco/');
			that.loader.setDRACOLoader(dracoLoader);

			that.loader.load(this.sense[this.sense_id].model+".glb", function (gltf) {
				let object = gltf.scene;
        		// that.animate = object.animations;
				// object.traverse( function ( child ) {
				//       if ( child.isMesh ) {

				//           child.material.specular.setScalar(0.1);
				//           // child.material.normalMap = normal;
				//       }
				//   } );

				if(gltf.animations.length>0){
					that.mixer = new THREE.AnimationMixer(object);
				// 查看動畫數據
				// obj.animations[0]：獲得剪輯對象clip
					let AnimationAction=that.mixer.clipAction(gltf.animations[0]);
				// AnimationAction.timeScale = 1; //默認1，可以調節播放速度
				// AnimationAction.loop = THREE.LoopOnce; //不循環播放
				// AnimationAction.clampWhenFinished=true;//暫停在最後一幀播放的狀態
					AnimationAction.play();//播放動畫
				}

				object.position.set(0, 50, that.valueZ); // 位置
				object.rotation.set(Math.PI / 2, 0, -Math.PI / 360);
				object.scale.set(that.currentScale.x, that.currentScale.y, that.currentScale.z);
				// object.scale.set(.3,.1,.1)
				that.scene.add(object);

				that.animate();

      		});
      		// 建立渲染器

			that.renderer = new THREE.WebGLRenderer();

			that.renderer.setPixelRatio(window.devicePixelRatio);

      		// // 設定渲染器的初始顏色

			that.renderer.setClearColor(0xffffff, 0);

			// // 設定輸出canvas畫面的大小
			that.renderer.setSize(window.innerWidth, window.innerHeight);
			container.appendChild(that.renderer.domElement);
			const controls = new OrbitControls(that.camera, that.renderer.domElement);
			controls.target.set(0, 12, 0);
			controls.update();
			window.addEventListener("resize", that.onWindowResize);
    	},
		onWindowResize() {
			this.camera.aspect = window.innerWidth / window.innerHeight;
			this.camera.updateProjectionMatrix();
			this.renderer.setSize(window.innerWidth, window.innerHeight);
		},
		animate() {
			requestAnimationFrame( this.animate );
			const delta = this.clock.getDelta();
			if ( this.mixer ) this.mixer.update( delta );
			this.renderer.render( this.scene, this.camera );
			this.stats.update();
		},
		// 	selectObject(event) {
		// 	debugger
		// 	if (event.button != 0) return;
		// 	const mouse = new THREE.Vector2();

		// 	mouse.x = (event.clientX / window.innerWidth) * 2 - 1;

		// 	mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
		// 	const raycaster = new THREE.Raycaster();

		// 	raycaster.setFromCamera(mouse, this.camera);
		// 	const intersected = raycaster.intersectObjects(

		// 		this.scene.children,

		// 		false
		// 	);

		//   if (intersected.length) {

		//     const found = intersected[0];

		//     const faceIndex = found.faceIndex;

		//     const geometry = found.object.geometry;

		//     const modelID = found.object.modelID;

		//   }
		// },
  	},

  	mounted() {

		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;
		if(windowHeight>windowWidth){
			this.valueZ = -0.2;
			this.currentScale = this.valueScale[1];
		}else{
			this.valueZ = -0.7;
			this.currentScale = this.valueScale[0];
		}
		if(this.scene_index!=undefined&&this.scene_index!=null){
			this.sense_id = this.scene_index.id;
		}
		this.clock = new THREE.Clock();
		this.init();
		this.animate();
		this.audio = document.createElement("audio");
  	},

  	computed:{
		test(){
			return this.sense[this.sense_id].view[this.value];
		},
		vrImg(){
			return `https://fisheryblobtest.blob.core.windows.net/vr-scene-${this.sense_id+1}/${this.test.img}.png`
		},
		text(){
			if(this.value==0){
				return '公司介紹'
			}else{
				return '製程解說'
			}
		}
	},
	beforeDestroy(){
		this.initService();
	}
}
</script>

<style lang="scss">
.introduce-menu{
	border-radius:.3em;
	position: absolute;
	bottom:2%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 136px;
	width: 320px;
	z-index:1;
	background: rgba(0, 0, 0, .2);
	backdrop-filter: blur(2px);
	font-weight: bolder;
	color:#fff;
	.container{
		display:flex;
		flex-direction: row;
		align-items:center;
		justify-content: space-around;
		border-radius:.3em;
		padding:.4em;
		background: rgba(0, 0, 0, .8);
	}
	.item{
		width:78px;
		display: flex;
		flex-direction: column;
		align-items:center;
		padding:.4em;
		border: .5px solid transparent;
		border-radius:.3em;
	}
	.item:hover{
		cursor: pointer;
		background:#000;
		box-shadow: 3px 3px 5px #424242;
		border: .5px solid slateblue;
	}
	.skip-btn{
		border-radius:5em;
		width:50px;
		height:50px;
		padding:.2em;
		border: 2px solid transparent;
		color:rgb(160, 160, 160);
		font-weight:bolder;
	}
	.skip-btn:hover{
		cursor: pointer;
		background:#000;
		border: 2px solid slateblue;
		color: slateblue;

	}
}

.back-btn{
		border-radius:5em 0 0 5em;
		border: 2px solid rgb(49, 49, 49);
		line-height: 50px;
		height:50px;
		color:#fff;
		font-weight:bolder;
		position: absolute;
		bottom:0px;
		right:0px;
		cursor: pointer;
		background:#000;
		width:200px;

	}
	.title{
		// line-height: 30px;
		// height:30px;
		color:#fff;
		font-weight:bolder;
		position: absolute;
    width:200px;
		top:40px;
		left:50%;
		padding:.5em 0 .5em 0;
		transform: translate(-50%, -50%);
		border-radius:5em;
		border: 2px solid rgb(106, 90, 205);
		background:rgba(0, 0, 0, .8);
		box-shadow:3px 3px 5px #424242;
	}
@media screen and (max-width:558px){
  .back-btn{
		border-radius:5em;
    transform: translate(-50%, -50%);
    left:50%;
    top:110px;
    border: 2px solid rgb(106, 90, 205);
		background:rgba(0, 0, 0, .8);
    box-shadow:3px 3px 5px #424242;
	}
  .introduce-menu{
    bottom:6%;
  }
}
</style>
