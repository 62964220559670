import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main'
import VRView from '@/views/VRView.vue'
import VRModelView from '@/views/VRModelView.vue'
import NotFound from '@/views/NotFound.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/vr',
    name: 'VRView',
    component: VRView,
    props: (route) => route.params
  },
  {
    path: '/mvr/:name',
    name: 'VRModelView',
    component: VRModelView,
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
