<template>
<div style="height:100%;overflow:auto;overflow-x:hidden;position:relative;">
  <div class="top-half">
  <h2 class="main-title">老闆帶看前店後廠<br/>
    <span style="color:#F5F5F5;font-size:1rem;margin:.2em;">III VR
    </span></h2>
    </div>
  <div class="main-introduce-container">
		<div class="main-introduce-item introduce1 introduce" @click="entryScene(0)">
			<img src='https://fisheryblobtest.blob.core.windows.net/vr-scene-1/part1.png' style="width:100%;"/>
      <h3 style="color:#fff;">工豐企業股份有限公司</h3>
      <img :src='logo_1' style="height:40px;"/>
      <div style="color:#E0E0E0;padding:1em;margin-top:1em;">工豐企業股份有限公司，成立於1980年，現代化廠房面積5000坪。專長於客製化手工具製造，產品客戶遍及全球，主要市場位於北美、歐洲，所設計、製造的產品受到客戶喜好並擁有良好口碑。</div>
		</div>
		<!-- <div class="main-introduce-item introduce2 introduce" @click="entryScene(1)">
			<img src='https://fisheryblobtest.blob.core.windows.net/vr-scene-2/part1.png' style="width:100%;"/>
      <h3 style="color:#fff;">世德工業股份有限公司</h3>
       <img :src='logo_2' style="height:40px;"/>
      <div style="color:#E0E0E0;padding:1em;margin-top:1em;">世德工業為國際汽車大廠零件供應商，主要業務為汽車零件及扣件之加工、製造及買賣，應用領域涵蓋汽車、機械、營建及電器等產業。</div>

		</div> -->
    <div class="main-introduce-item introduce3 introduce" @click="entryScene(2)">
			<img src='https://fisheryblobtest.blob.core.windows.net/vr-scene-3/part1.png' style="width:100%;"/>
      <h3 style="color:#fff;">億曜企業股份有限公司</h3>
      <img :src='logo_3' style="height:40px;"/>
      <div style="color:#E0E0E0;padding:1em;margin-top:1em;">億曜企業股份有限公司，成立於1975年。為放電加工機，以及線切割機的專業製造廠，台灣第一家擁有航太等級的設備控制器。</div>

		</div>
  </div>
  <div style="background:#292929;width:30%;box-shadow:0px 0px 25px 5px #000;margin:0 auto;"></div>
  <div class="bottom-half"></div>
</div>
</template>

<script>
import logo_1 from '@/assets/logo_1.png'
import logo_2 from '@/assets/logo_2.png'
import logo_3 from '@/assets/logo_3.png'
export default {
	name: 'Main',
	components: {

  },
  data () {
		return {
      logo_1: logo_1,
      logo_2: logo_2,
      logo_3: logo_3
    }
  },
	methods:{
		entryScene(index){
			this.$router.push({
				name: 'VRView',
				params: {
					scene_index:{
						id: index
					}
				}
			});
		}
	}
}
</script>
<style scoped>
.main-introduce-container{
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  align-items:center;
  justify-content:
  space-around;
  height:80%;
}
.main-introduce-item{
  position:relative;
  width:30%;
  flex-grow:1;
  height:620px;
  margin:.5em;
  background:rgba(32, 32, 32,.8);
  border: 3px solid transparent;
}
.main-introduce-item:hover{
  border: 3px solid slateblue;
}
@media screen and (max-width:1000px){
	.main-introduce-item{
    position:relative;
    flex:1 0 100%;
    /* height:100%; */
    margin:.5em;
    /* font-size:12px; */
    background:#202020;
    border: 3px solid transparent;
  }
  .main-introduce-container{
    height:90%;
  }
}
@media screen and (min-width:558px) and (max-width:1000px){
  .main-introduce-item{
    position:relative;
    flex:1 0 100%;
    height:100%;
    margin:.5em;
    /* font-size:14px; */
    background:#202020;
    border: 3px solid #000;
  }
}
.main-title{
  width:100%;
  color:#fff;
  margin:0;
  padding:1em;
  /* background:#151515; */
  /* background: -webkit-linear-gradient(left,#000, rgba(44, 48, 56,0.5),rgb(73, 63, 139), rgba(44, 48, 56,0.5),#000);
  background: -o-linear-gradient(right,#000,rgba(44, 48, 56,0.5),rgb(73, 63, 139), rgba(44, 48, 56,0.5),#000);
  background: -moz-linear-gradient(right,#000,rgba(44, 48, 56,0.5),rgb(73, 63, 139), rgba(44, 48, 56,0.5),#000);
  background: linear-gradient(to right,#000,rgba(44, 48, 56,0.5),rgb(73, 63, 139), rgba(44, 48, 56,0.5),#000); */
}
@media screen and (min-width:1001px){
  .introduce1{
        transform:perspective(1000px) rotateY(10deg);
  }
  .introduce2{
    height:586px;
  }
  .introduce3{
        transform:perspective(1000px) rotateY(-10deg);
  }
  .introduce:hover{
    transform:none;
      height:650px;
      width:33%;
  }
}
.top-half{
  /* transform:rotateX(90deg); */
  width:100%;
  /* height: 30px; */
    border-radius: 0 0 50% 50%;
  background: -webkit-linear-gradient(left,#000, rgba(44, 48, 56,0.5),rgb(73, 63, 139), rgba(44, 48, 56,0.5),#000);
  background: -o-linear-gradient(right,#000,rgba(44, 48, 56,0.5),rgb(73, 63, 139), rgba(44, 48, 56,0.5),#000);
  background: -moz-linear-gradient(right,#000,rgba(44, 48, 56,0.5),rgb(73, 63, 139), rgba(44, 48, 56,0.5),#000);
  background: linear-gradient(to right,#000,rgba(44, 48, 56,0.5),rgb(73, 63, 139), rgba(44, 48, 56,0.5),#000);
}
.bottom-half{
  position:absolute;
  z-index:-1;
  bottom:10px;
  /* bottom:0; */
  /* padding:1em; */
  /* transform:rotateX(90deg); */
  width:100%;
  height: 30%;
  border-radius: 50% 50% 0 0;
  background: -webkit-linear-gradient(bottom,rgb(82, 78, 126), rgba(44, 48, 56,0.5),#000);
  background: -o-linear-gradient(top,rgb(82, 78, 126), rgba(44, 48, 56,0.5),#000);
  background: -moz-linear-gradient(top,rgb(82, 78, 126), rgba(44, 48, 56,0.5),#000);
  background: linear-gradient(to top,rgb(82, 78, 126), rgba(44, 48, 56,0.5),#000);
  }
</style>
